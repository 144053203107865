import React from 'react'
import Apple from '../images/icons/App-Store.svg'
import Facebook from '../images/icons/Facebook.svg'
import Google from '../images/icons/Google-Play.svg'
import Instagram from '../images/icons/Instagram.svg'
import TikTok from '../images/icons/TikTok.svg'
import { selectMyLocal } from "../state/store"
import { useSelector } from "react-redux"
import './scss/Socials.scss'

export const Socials = () => {
  const myLocal = useSelector(selectMyLocal)
  return (
    <div>
      {myLocal.location &&
        <div className='socials__iconscontainer'>
          <div className="socials__icons">
            <a target="_blank" rel="noopener noreferrer" href={myLocal.location.instagram__url}><img src={Instagram} alt='Instagram icon' /></a>
          </div>
          {myLocal.location.facebook_url &&
            <div className="socials__icons">
              <a target="_blank" rel="noopener noreferrer" href={myLocal.location.facebook_url}><img src={Facebook} alt='Facebook icon' /></a>
            </div>
          }
          <div className="socials__icons">
              <a target="_blank" rel="noopener noreferrer" href={'https://www.tiktok.com/@sportingglobe'}><img src={TikTok} alt='TikTok icon' /></a>
            </div>
        </div>
      }
    </div>
  )
}

export const AppStores = () => {
  return (
    <>
      <div className="socials__stores">
        <a target="_blank" rel="noopener noreferrer" href="https://apps.apple.com/au/app/sporting-globe/id1077184775"><img src={Apple} alt='Apple App store icon' /></a>
      </div>
      <div className="socials__stores">
        <a target="_blank" rel="noopener noreferrer" href="https://play.google.com/store/apps/details?id=com.sportingglobe.sportingglobe&hl=en"><img src={Google} alt='Google play store icon' /></a>
      </div>
    </>
  )
}
