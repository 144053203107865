import React, { useState } from 'react'
import PropTypes from "prop-types"
import { Button } from './Button'
import fullstar from '../images/fullstar.svg'
import { Link } from 'gatsby'
import { selectMyLocal } from "../state/store"
import { useSelector } from "react-redux"
import './scss/LocationsList.scss'

/**
 * Contains the rendered locations under their respective state. 
 * There is different functionality based on if this is the booking page or not.
 * 
 * @param {function} onClickFunction handles when a location is clicked
 * @param {string} type if this is the booking page or not 
 * @param {object} data the location data
 * @param {function} returnState 
 * @returns a rendered state based list of locations
 */
export const LocationsList = ({ onClickFunction, type, data, returnState }) => {
  const myLocal = useSelector(selectMyLocal)
  const [activeState, setActiveState] = useState(myLocal !== '' ? myLocal.location.state : 'VIC')
  return (
    <div className='location__list'>
      <div className="state__buttons">
        {Object.keys(data).map((state, key) =>
          <React.Fragment key={key}>
            {state !== 'null' &&
              <Button key={key} onClickFunction={() => { setActiveState(state), returnState(state) }} text={state} size='normal' isDisabled={activeState === state ? '' : 'button__disabled'} />
            }
          </React.Fragment>
        )}
      </div>
      {Object.keys(data).map((state, key) =>
        <div className={`${type === 'location' ? 'location__list__picker' : ''}`} key={key}>
          {data[state].map((location, key) =>
            <div key={key} className={`${type === 'location' ? 'location__list__locations' : ''}`} style={{ display: state !== activeState ? 'none' : 'unset' }}>
              <div key={key}>
                {type === 'page' ?
                  <Link key={key}
                    to={`/locations/${location.url}/`}>
                    {myLocal.location && location.url === myLocal.location.url &&
                      <img src={fullstar} alt='Active Srar' />
                    }
                    <span>{location.title}</span>
                    {location.coming_soon === true &&
                      <span className='coming__soon'>Coming <br /> Soon</span>
                    }
                  </Link>
                  : type === 'book' ?
                    <>{location.book === false && <p onClick={() => onClickFunction(location.url, location.title)} key={key}>{location.title}</p>}</>
                    : <><p onClick={() => onClickFunction(location.url, activeState)} key={key}>{location.title}</p>
                      {location.coming_soon === true &&
                        <span className='coming__soon__location'>Coming <br /> Soon</span>
                      }</>
                }
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

LocationsList.propTypes = {
  onClickFunction: PropTypes.func,
  type: PropTypes.string,
  data: PropTypes.object.isRequired,
  returnState: PropTypes.func
}
