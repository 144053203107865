import { useEffect, useReducer } from 'react'
import { api } from "../env"
import axios from 'axios'
import config from '../config'
import { API_ROOT } from "gatsby-env-variables"

/**
 * This hook is responsible for making api calls.
 * @param {string} url holds the url to be called.
 * @returns A state object with a status, data and error (null of no error).
 */
export const useAPI = (url, type) => {

  const baseUrl = API_ROOT
  const initialState = {
    status: 'idle',
    error: null,
    data: [],
  }
  const [state, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      case 'FETCHING':
        return { ...initialState, status: 'fetching' }
      case 'FETCHED':
        return { ...initialState, status: 'fetched', data: action.payload }
      case 'FETCH_ERROR':
        return { ...initialState, status: 'error', error: action.payload }
      default:
        return state
    }
  }, initialState)

  useEffect(() => {
    let mounted = true
    const fetchData = async () => {
      dispatch({ type: 'FETCHING' })

      try {
        const data = await axios.get(baseUrl + url)
        if (mounted) {
          dispatch({ type: "FETCHED", payload: data.data })
        }
        // dispatch({ type: 'FETCHED', payload: data.data })
      } catch (error) {
        // fakeApiCall()
        dispatch({ type: 'FETCH_ERROR', payload: error.message })
      }
    }


    if (url === null || url === 'null' || url === '/location/global') {
      dispatch({ type: 'FETCH_ERROR', payload: 'error' })
    } else {
      fetchData()

    }
    return () => mounted = false
  }, [url])
  return state
}
