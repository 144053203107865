import { createSlice } from '@reduxjs/toolkit'


// Set initial values for any store items.
const initialState = {
  location: '',
  locationList: '',
  myLocal: '',
  filters: [],
  menuSelected: '',
  whatsonFilters: [],
  whatsonSelected: 'This Week',
  home: [],
  hideNav: false
}

export const locationSlice = createSlice({
  name: 'location',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setLocation: (state,action) => {
      state.location = action.payload
    },
    setLocationList: (state,action) => {
      state.locationList = action.payload
    },
    setMyLocal: (state,action) => {
      state.myLocal = action.payload
    },
    setFilters: (state, action) => {
      state.filters = action.payload
    },
    setMenuSelected: (state, action) => {
      state.menuSelected = action.payload
    },
    setWhatsonFilters: (state, action) => {
      state.whatsonFilters = action.payload
    },
    setWhatsonSelected: (state, action) => {
      state.whatsonSelected = action.payload
    },
    setHome: (state, action) => {
      state.home = action.payload
    },
    setHideNav: (state, action) => {
      state.hideNav = action.payload
    }
  },
})
  
export const { setLocation, setLocationList, setMyLocal, setMenuSelected, setFilters, setWhatsonFilters, setWhatsonSelected, setHome, setHideNav } = locationSlice.actions
  
// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectLocation = (state) => state.store.location
export const selectLocationList = (state) => state.store.locationList
export const selectMyLocal = (state) => state.store.myLocal
export const selectFilters = (state) => state.store.filters
export const selectMenuSelected = (state) => state.store.menuSelected
export const selectWhatsOnFilters = (state) => state.store.whatsonFilters
export const selectWhatsonSelected = (state) => state.store.whatsonSelected
export const selectHome = (state) => state.store.home
export const selectHideNav = (state) => state.store.hideNav



export default locationSlice.reducer

  