import React from 'react'
// import loading from '../images/TSG-Loading-Icon.gif'

/**
 * Loading animation gif 
 */
export const Loading = () => {
  return (
    <div className='loading'>
      <div>
        <img alt='Loading animation' src={'https://d3eb7jruaiam6b.cloudfront.net/images/video/tsg-loading-icon'} />
      </div>
    </div>
  )
}
