import React, { useState, useEffect } from 'react'
import { CLOUDFRONT } from "gatsby-env-variables"
import { Loading } from "../components/Loading"
import { LocationsList } from './LocationsList'
import { setLocationList } from "../state/store"
import shield from '../images/shield.svg'
import { useAPI } from '../util/useAPI'
import { useCookies } from "react-cookie"
import { useDispatch } from "react-redux"
import { useWindowSize } from '../util/useWindowSize'
import _ from 'lodash'
import './scss/LocationSelector.scss'

/**
 * The location selector that gets displayed to users with no location cookie.
 * @returns a list of locations displayed over a large background image.
 */
export const LocationSelector = () => {
  const [isLoading, setIsLoading] = useState(true)
  // eslint-disable-next-line no-unused-vars
  const [url, setUrl] = useState('/locations')
  const [locationList, setLocationLists] = useState()
  const [cookies, setCookie] = useCookies(["location"])
  const { status, data, error } = useAPI('/v1/locations')
  const dispatch = useDispatch(setLocationList)
  const size = useWindowSize()

  /**
   * On @param status change, check if api call is loading or not.
   * If still loading then the loading gif will be displayed.
   * Once data is fetched it will then set the locations and then display them to the user.
   */
  useEffect(() => {
    if (status === 'fetched') {
      setLocationLists(_.groupBy(data.locations.data, 'state'))
      setIsLoading(false)
      if (setLocationLists !== locationList) {
        dispatch(setLocationList(locationList))
        setIsLoading(false)
      }
    }
    if (status === 'fetching') {
      setIsLoading(true)
    }
  }, [status])

  /**
   * Called on click of a location. Cookie is set first with a 30 day expiration date.
   * Then the data layer is pushed to google tag manager.
   * 
   * @param {string} location the location that has been clicked
   * @param {string} state the state the location belongs in
   */
  const setLocation = (location, state) => {
    setCookie('location', location, { path: '/', maxAge: 2.592e+6 })
    window.dataLayer.push({
      "event": "custom.restaurant.set",
      "restaurant": {
        "name": location,
        "state": state
      }
    })
  }

  return (
    <div className='location__selector' style={{ backgroundImage: size.width < 1050 ? `url(${CLOUDFRONT}/members/website-welcome-screen-mobile)` : `url(${CLOUDFRONT}/members/website-welcome-screen-desktop)` }}>
      <img alt='The Sporting Globe shield logo' src={shield} alt="" />
      <h2>Choose Your Location</h2>
      {locationList &&
        <LocationsList data={locationList} onClickFunction={setLocation} />
      }
      {isLoading === true &&
        <Loading />
      }
    </div>
  )
}
