import React, { useEffect, useState, useRef } from "react"
import PropTypes from "prop-types"
import { Button } from "../components/Button"
import * as dayjs from 'dayjs'
import { Footer } from "../components/Footer"
import { Loading } from "../components/Loading"
import { LocationsList } from "../components/LocationsList"
import { LocationSelector } from '../components/LocationSelector'
import { MenuContent } from "../components/MenuContent"
import { Navbar } from "../components/Navbar"
import { navigate } from "gatsby"
import _ from 'lodash'
import { push as Menu } from 'react-burger-menu'
import { selectLocation, setLocation, setMyLocal, setHideNav, selectMyLocal } from "../state/store"
import { useAPI } from "../util/useAPI"
import { useCookies } from "react-cookie"
import { useSelector, useDispatch, } from "react-redux"

const Layout = ({ children, pageContext }) => {
  const myLocal = useSelector(selectMyLocal)
  const { status, data, error } = useAPI('/v1/locations')
  const burgerButton = useRef()
  const [showPicker, setShowPicker] = useState(false)
  const [isScrolled, setIsScrolled] = useState(false)
  const [cookies, setCookie] = useCookies(["location"])
  const [isLoading, setIsLoading] = useState(true)
  const [locationData, setLocationData] = useState([])
  const [showNav, setShowNav] = useState()
  const [hours, setHours] = useState([])
  const [showStates, setShowStates] = useState(false)
  // const [showNavPicker, setShowNavPicker] = useState(false)
  let pathname = typeof window !== "undefined" ? window.location.pathname.split('/') : ""
  let cleanPath = typeof window !== "undefined" ? pathname.filter(e => e) : []
  const location = useSelector(selectLocation)
  let url = location
  useEffect(() => {
    // Handle mt druitt cookie and links.
    if (cookies.location === 'mt-druitt') {
      setCookie('location', 'king-street-wharf', { path: '/', maxAge: 2.592e+6 })
    }
    if (cleanPath[(cleanPath.length - 1)] === 'mt-druitt') {
      navigate('/')
    }

    if (status === 'fetched') {
      const locations = data.locations.data.map(location => { return location.url })
      setLocationData(locations)
      if (cookies.location === undefined && locations.includes(cleanPath[(cleanPath.length - 1)]) === true && cleanPath[0] !== undefined) {
        setCookie('location', cleanPath[(cleanPath.length - 1)], { path: '/', maxAge: 2.592e+6 })
      }
    }
  }, [status])

  useEffect(() => {
    setShowNav(window.location.search === '?hide=true' ? false : true)
    setHideNav(window.location.search === '?hide=true' ? true : false)
    if (burgerButton.current !== undefined) {
      burgerButton.current.children[0].children[1].children[0].className = `bm-burger-button`
    }
  }, [])


  useEffect(() => {
    if (typeof window !== "undefined") {
      if (
        cookies.location &&
        (cleanPath[0] === 'menu' || cleanPath[0] === 'whatson' || cleanPath[0] === 'functions') &&
        (locationData.length !== 0 && locationData.includes(cleanPath[(cleanPath.length - 1)]) === false)
      ) {
        navigate(`${window.location.pathname}${window.location.pathname.charAt(window.location.pathname.length - 1) === '/' ? '' : '/'}${cookies.location}/${window.location.hash}`)
      } else if ((locationData.length !== 0 && locationData.includes(cleanPath[0]) === false) && cleanPath.length !== 0) {
        // navigate(`/${cookies.location}${window.location.pathname}`)
      }
    }
  }, [cookies.location, locationData])

  const testing = useAPI(`/v1/location/${url === '' ? 'global' : url}`)
  const dispatch = useDispatch(setLocation, setMyLocal, setHideNav)
  const [open, setOpen] = useState(false)
  const [test, setTest] = useState(false)

  // Watches the cookie to see if location has changed, if so, set the mylocal location and mylocal data to the store.
  useEffect(() => {
    if (cookies.location) {
      if (cookies.location !== 'global') {
        setTest(false)
        dispatch(setLocation(cookies.location))
      }
    } else if ((typeof window !== "undefined" && location === '') || pathname.length !== 0) {
      setTest(true)
    }
  }, [cookies])
  let dayHours
  useEffect(() => {
    if (testing.status === 'fetched') {
      dayHours = dayjs().format('dddd')
      setHours(testing.data.location.daily_hours[dayHours.toLowerCase()])
      dispatch(setMyLocal(testing.data))
      setTimeout(function () { setIsLoading(false) }, 500)
    }
    if (testing.status === 'fetching') {
      setIsLoading(true)
    }
  }, [testing.status])

  const isMenuOpen = function (state) {
    setOpen(state.isOpen)
  }

  useEffect(() => {
    if (burgerButton.current !== undefined) {
      burgerButton.current.children[0].children[1].children[0].className = `bm-burger-button ${isScrolled === true ? 'scrolled' : ''}`
    }
  }, [isScrolled])

  const scrollTo = (ele) => {
    try {
      setTimeout(function () { typeof window !== "undefined" && ele.current.scrollTo({ left: 0, top: 0, behavior: "smooth" }) }, 80)
    } catch {
      console.log('none')
    }
  }
  var styles = {
    bmBurgerButton: {
      display: !showNav ? 'none' : showNav
    }
  }

  const changeLocation = (url, state) => {
    setCookie('location', url, { path: '/', maxAge: 2.592e+6 })
    window.dataLayer.push({
      "event": "custom.restaurant.set",
      "restaurant": {
        "name": url,
        "state": state
      }
    })
    // navigate(`${window.location.pathname}${window.location.pathname.charAt(window.location.pathname.length - 1) === '/' ? '' : '/'}${url}`)
    setShowPicker(false)
    setShowStates(false)
    if (locationData.includes(cleanPath[(cleanPath.length - 1)]) === true) {
      cleanPath[(cleanPath.length - 1)] = url
      let newUrl = ''
      for (let index = 0; index < cleanPath.length; index++) {
        newUrl += `/${cleanPath[index]}`
      }
      navigate(newUrl)
    }
  }

  useEffect(() => {
    if (showPicker === true || test === true) {
      document.body.style.overflow = "hidden"
    } else {
      document.body.style.overflow = "unset"
    }
  }, [showPicker, test])

  return (
    <div className={`app`} style={{ overflow: test === true ? 'hidden' : 'unset' }}>

      <div style={{ height: '100%', overflow: 'hidden' }} id={"outer-container"} ref={burgerButton} className={open === true ? 'outer-container open' : 'outer-container closed'}>

        <Menu styles={styles} onStateChange={isMenuOpen} isOpen={open} width={'100%'} right pageWrapId={"page-wrap"} outerContainerId={"outer-container"} >
          <MenuContent onClickFunction={(e) => { setOpen(false), scrollTo(e) }} />
        </Menu>
        <div id="page-wrap" style={{ height: '100%' }}>

          <Navbar groupLocations={data.length !== 0 ? _.groupBy(data.locations.data, 'state') : ''} changeLocation={(url, state) => changeLocation(url, state)} isHidden={showNav} isScrolling={(e) => { setIsScrolled(e) }} />
          <div style={{ minHeight: `${myLocal.location && myLocal.location.settings.coming_soon ? 'unset' : '1000px'}`, overflow: `${showPicker === true ? 'hidden' : 'unset'}` }} >
            {showPicker &&
              <div className='overlay' onClick={() => { setShowPicker(false), setShowStates(false) }}></div>
            }
            
            {children}
          </div>
          <div className={`footer__sticky ${!showNav ? 'bm-burger-hide' : pathname.includes('functionquote') ? 'bm-burger-hide' : showNav} ${showPicker ? 'footer__sticky__picker' : ''}`} >
            {!showStates ?
              <div className={`footer__sticky__content`} onClick={() => setShowPicker(showPicker === false ? true : false)}>

                <div>
                  <svg xmlns="http://www.w3.org/2000/svg" width="19.479" height="27.75" viewBox="0 0 19.479 27.75">
                    <g id="Group_170" data-name="Group 170" transform="translate(-326.605 -22.695)">
                      <g id="Group_94" data-name="Group 94" transform="translate(326.605 22.695)">
                        <path id="Path_333" data-name="Path 333" d="M336.343,22.695a9.673,9.673,0,0,0-9.653,10.953c.832,7.6,9.026,16.373,9.026,16.373a1.593,1.593,0,0,0,.416.339h.009l.271.085.271-.085h.008a1.658,1.658,0,0,0,.424-.339s8.077-8.78,8.891-16.39a11.29,11.29,0,0,0,.076-1.2,9.741,9.741,0,0,0-9.741-9.738Zm0,16.009a6.266,6.266,0,1,1,6.261-6.27,6.275,6.275,0,0,1-6.261,6.27Zm0,0" transform="translate(-326.605 -22.695)" />
                      </g>
                    </g>
                  </svg>
                  <p>Home Location</p></div><p>{cookies.location && cookies.location.split('-').join(' ')} <i className={`arrow ${showPicker ? 'down' : 'right'}`}></i></p>
              </div>
              : <div className={`footer__sticky__content`} onClick={() => setShowStates(false)}>
                <div>
                  <p><i className={`arrow left`}></i>Back</p></div><p>Change your location</p>
              </div>
            }
            {showPicker &&
              <>
                {!showStates &&
                  <>
                    <div className='location__buttons'>
                      <div className="location__buttons__row">
                        {myLocal.location.settings.location.call === false && <Button onClickFunction={() => window.location = `tel:${myLocal.location.phone}`} text='CALL' />}
                        {myLocal.location.settings.location.email === false && <Button onClickFunction={() => window.location = `mailto:${myLocal.location.email}`} text='EMAIL' />}
                        <Button onClickFunction={() => { navigate('/locations/' + myLocal.location.url), setShowPicker(false) }} text='MORE' />
                      </div>
                    </div>
                    {hours !== undefined && <p className='footer__hours'>Today's Hours: {hours.length === 1 ? hours[0] : hours[0] + ', ' + hours[1]}</p>}
                    <hr />
                    <div className='footer__change__location'>
                      <Button onClickFunction={() => setShowStates(true)} text='CHANGE LOCATION' />
                    </div>
                  </>
                }
                {showStates && <LocationsList type='location' onClickFunction={(url, state) => changeLocation(url, state)} data={_.groupBy(data.locations.data, 'state')} />}
              </>}
          </div>
          <div key={'footer'} className={`footer__container ${!showNav || pathname.includes('functionquote') ? 'bm-burger-hide' : showNav}`} id='footer'>
            <Footer />
          </div>
        </div>
      </div>
      {
        isLoading === true && test !== true &&
        <Loading />
      }
            {typeof window !== "undefined" && test === true && <LocationSelector /> }

    </div >
  )
}

Layout.propTypes = {
  children: PropTypes.object.isRequired,
}

export default Layout

