import React, { useEffect, useState } from "react"
import PropTypes from 'prop-types'
import { Button } from "./Button"
import * as dayjs from 'dayjs'
import { Link, navigate } from "gatsby"
import logo from '../images/TSG-Shield.svg'
import { selectMyLocal, selectLocation } from "../state/store"
import { useSelector } from "react-redux"
import "./scss/Navbar.scss"
import { LocationsList } from "./LocationsList"

/**
 * Builds out the navigation bar at the top of the website.
 * 
 * @param {function} isScrolling set if the user is scrolling or not
 * @param {boolean} isHidden used for hidding the burger menu or not
 * @param {function} changeLocation changing the set location
 * @param {string} groupLocations the locations grouped in their states
 * @param {*} setNavPicker
 * @returns the rendered navbar
 */
export const Navbar = ({ isScrolling, isHidden, changeLocation, groupLocations, setNavPicker }) => {
  let pathname = typeof window !== "undefined" ? window.location.pathname : ""
  let listener = null
  const [scrollState, setScrollState] = useState("no")
  const [showPicker, setShowPicker] = useState(false)
  const mainOptions = ["Menu", "Book Now", "What’S On", "Tipping", "Functions", "Locations", "Members", "Order"]
  const myLocalName = useSelector(selectLocation)
  const myLocal = useSelector(selectMyLocal)
  const path = pathname.split('/')
  const [showStates, setShowStates] = useState(false)
  const [state, setState] = useState()
  let dayHours = dayjs().format('dddd')
  let hours = myLocal.location && myLocal.location.daily_hours[dayHours.toLowerCase()]

  /**
   * Listens for when the users local changes and updates the state
   */
  useEffect(() => {
    if (myLocal.location) {
      setState(myLocal.location.state)
    }
  }, [myLocal])

  /**
   * Listens for the user scrolling and adjusts the top navbar styling based on scrolling.
   */
  useEffect(() => {
    listener = document.addEventListener("scroll", e => {
      var scrolled = document.scrollingElement.scrollTop
      if (scrolled >= 100) {
        if (scrollState !== "scrolled") {
          setScrollState("scrolled")
          isScrolling(true)
        }
      } else {
        if (scrollState !== "top") {
          setScrollState("no")
          isScrolling(false)
        }
      }
    })
    return () => {
      document.removeEventListener("scroll", listener)
    }
  }, [scrollState])

  /**
   * Creates a url based on the nav options
   * 
   * @param {string} option a nav options title
   * @returns a string that has changed the option name into the appropriate url
   */
  const createLink = (option) => {
    const url = ((option.toLocaleLowerCase()).replace(' ', '')).replace(/’/g, '')
    if (url === 'menu' || url === 'whatson' || url === 'functions') {
      return `/${url}${myLocalName === '' ? '' : '/' + myLocalName}`
    } else if (url === 'booknow') {
      return '/book/'
    } else {
      return `/${url}/`
    }
  }

  return (
    <div className={`navbar ${!isHidden ? 'bm-burger-hide' : ''} ${scrollState} ${pathname === `/${path[1]}/menu/` ? 'navbar__solid' : pathname.includes('menu') ? 'navbar__solid' : pathname.includes('whatson') ? 'navbar__solid' : pathname.includes('franchise') ? 'navbar__solid' : pathname.includes('functions') || pathname.includes('careers') ? 'navbar__solid' : ''} ${path[1] === 'tipping' ? 'navbar__iframe navbar__solid' : ''}`}>
      {showPicker &&
        <div className='overlay' onClick={() => { setShowPicker(false), setShowStates(false) }}></div>
      }
      <nav className={`navbar__container ${!isHidden ? 'bm-burger-hide' : ''}`}>
        <div className='navbar__logo__container'>
          <Link to="/"><img className={`navbar__logo ${scrollState} `} src={logo} alt='The Sporting Globe' /></Link>
        </div>
        <div className="menu__items">
          <ul className="navbar__links">
            {mainOptions.map((option) =>
              <li key={option}>
                {option !== 'Tipping' ?
                  <Link to={createLink(option)}>{option}</Link>
                  : <a target='_blank' href="https://tipping.sportingglobe.com.au/" rel="noreferrer">{option}</a>
                }
              </li>
            )}

          </ul>
          <div className='navbar__order'>
            <Button onClickFunction={() => navigate('/order')} text='ORDER' size='small' />
          </div>
          <div className='navbar__location'>
            <div className='navbar__location__2' onClick={() => { setShowPicker(showPicker === true ? false : true) }}>
              <svg xmlns="http://www.w3.org/2000/svg" width="24.479" height="32.75" viewBox="0 0 19.479 27.75">
                <g id="Group_170" data-name="Group 170" transform="translate(-326.605 -22.695)">
                  <g id="Group_94" data-name="Group 94" transform="translate(326.605 22.695)">
                    <path id="Path_333" data-name="Path 333" d="M336.343,22.695a9.673,9.673,0,0,0-9.653,10.953c.832,7.6,9.026,16.373,9.026,16.373a1.593,1.593,0,0,0,.416.339h.009l.271.085.271-.085h.008a1.658,1.658,0,0,0,.424-.339s8.077-8.78,8.891-16.39a11.29,11.29,0,0,0,.076-1.2,9.741,9.741,0,0,0-9.741-9.738Zm0,16.009a6.266,6.266,0,1,1,6.261-6.27,6.275,6.275,0,0,1-6.261,6.27Zm0,0" transform="translate(-326.605 -22.695)" />
                  </g>
                </g>
              </svg>
              <div className='navbar__location__name' >
                <p>Home Location</p>
                <p>{myLocalName.split('-').join(' ')}<i className={`arrow ${showPicker === false ? 'right' : 'down'}`}></i></p>
              </div>
            </div>
            <div className={`desktop__picker ${showPicker === false ? '' : 'desktop__picker__active'}`} style={{ height: `${!showPicker ? '0' : showStates ? (groupLocations[state].length * 37) + 130 + 'px' : '307px'}`, transition: 'height 0.5s ease' }}>
              <>
                {!showStates &&
                  <>
                    <div className='location__buttons'>
                      <p className='footer__hours'>{myLocal.location && myLocal.location.settings.location.call === false && myLocal.location.phone}</p>

                      {myLocal.location && myLocal.location.settings.location.call === false && <Button onClickFunction={() => window.location = `mailto:${myLocal.location.email}`} text='EMAIL' />}
                      <Button onClickFunction={() => { navigate('/locations/' + myLocal.location.url), setShowPicker(false) }} text='MORE' />
                    </div>
                    {hours !== undefined && <p className='footer__hours'>Today's Hours: <br />{hours.length === 1 ? hours[0] : hours[0] + ', ' + hours[1]}</p>}
                    <div className='footer__change__location'>
                      <Button onClickFunction={() => setShowStates(true)} text='CHANGE LOCATION' />
                    </div>
                  </>
                }
                {showStates &&
                  <>
                    <LocationsList type='location' onClickFunction={(url, state) => { changeLocation(url, state), setShowPicker(false), setShowStates(false) }} data={groupLocations} returnState={(state) => setState(state)} />
                    <div className='navbar__location__back'>
                      <p onClick={() => { setShowStates(false), setState(myLocal.location.state) }}><i className='arrow left' />Back</p>
                    </div>
                  </>
                }
              </>
            </div>

          </div>
        </div>
      </nav>
    </div >
  )
}

Navbar.propTypes = {
  isScrolling: PropTypes.func,
  isHidden: PropTypes.bool,
  changeLocation: PropTypes.func,
  groupLocations: PropTypes.any,
  setNavPicker: PropTypes.bool
}
