import React from 'react'
import PropTypes from "prop-types"
import './scss/Button.scss'

/**
 * Creates a button to be used across the website, it can be large or small.
 * 
 * @param {function} onClickFunction the function to be called on button click
 * @param {string} text the text to be displayed on the button
 * @param {string} size if the button is "large" or "small"
 * @param {boolean} isDisabled if the button is disabled (true) or not (false)
 * @param {string} icon a url of an icon to be displayed in the button with the text.
 * @param {number} id 
 * @returns A rendered button with the above settings.
 */
export const Button = ({ onClickFunction, text, size, isDisabled, icon, id }) => {
  const buttonSize = size => size === 'small' ? 'button__small' : 'button__normal'
  return <button id={id} disabled={isDisabled === 'disabled' ? true : false} className={buttonSize(size) + ' ' + isDisabled} onClick={onClickFunction}>
    {icon &&
      <img src={icon} />
    }
    {text}
  </button>
}

/**
 * @todo is this needed?
 * 
 * @param {function} onClickFunction 
 * @param {string} text
 * @param {boolean} disabled
 * @returns 
 */
export const ButtonToggle = ({onClickFunction, text, disabled}) => {
  return <button onClick={onClickFunction} className={`button__toggle ${disabled ? 'button__toggle__off' : 'button__toggle__on'}`}>
    {text}
  </button>
}

Button.propTypes = {
  onClickFunction: PropTypes.func,
  text: PropTypes.string.isRequired,
  size: PropTypes.string,
  isDisabled: PropTypes.string,
  icon: PropTypes.string,
  id: PropTypes.any
}

ButtonToggle.propTypes = {
  onClickFunction: PropTypes.func,
  text: PropTypes.string.isRequired,
  disabled: PropTypes.bool
}