const dev = {
  API_ROOT: "https://dev-api.sportingglobe.com.au/api",
  env: 'dev'
}

const staging = {
  API_ROOT: "https://prod-api.sportingglobe.com.au/api/",
  env: 'staging'
}

const prod = {
  API_ROOT: "https://prod-api.sportingglobe.com.au/api/",
  env: 'prod'
}

const config = {
  // Add common config values here
  // Default to dev if not set
  ...(process.env.BUILD_ENV === "development" ? dev : process.env.BUILD_ENV === "staging" ? staging : prod),
}

export default config