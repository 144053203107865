import React from "react"
import { Provider } from "react-redux"
import { createStore as reduxCreateStore } from "redux"
import rootReducer from "."
import { CookiesProvider } from "react-cookie"

const createStore = () => reduxCreateStore(rootReducer)

const wrapper = ({ element }) => (
  <CookiesProvider>
    <Provider store={createStore()}>{element}</Provider>
  </CookiesProvider>
)

export default wrapper
