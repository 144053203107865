import React from 'react'
import { Link } from 'gatsby'
import { Socials, AppStores } from './Socials'
import './scss/Footer.scss'

/**
 * This is the footer for the website.
 * 
 * @returns the rendered footer component.
 */
export const Footer = () => {
  const date = new Date()
  return (
    <div className='footer'>
      <h5 className='footer__header'>Eat. Drink. Sport.</h5>
      <ul className='footer__nav'>
        <li><p><Link to='/feedback/'>Feedback</Link></p></li>
        <li><p><Link to='/gift-cards/'>Gift Cards</Link></p></li>
        <li><p><Link to='/partners/'>Partners</Link></p></li>
        <li><p><Link to='/sponsorship/'>Sponsorship</Link></p></li>
        <li><p><Link to='/careers/'>Careers</Link></p></li >
        <li><p><Link to='/franchise/'>Franchising</Link></p></li >
      </ul >
      <div className='footer__socials__container'>
        <div className="footer__socials">
          <Socials />
        </div>
        <div className="footer__appStores">
          <AppStores />
        </div>
      </div>
      <div className='footer__socials__container'>
        <ul>
          <li><Link to='/privacy-policy/'>Privacy</Link></li>
          <li><Link to='/terms-conditions/'>Terms & Conditions</Link></li>
        </ul>
        <p>© The Sporting Globe {date.getFullYear()}</p>
      </div>
    </div >
  )
}
